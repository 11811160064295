import axios, { AxiosHeaders } from 'axios'
// import clone from 'nanoclone'
// import { ref, markRaw } from 'vue'
import { Pinia } from 'pinia'
import { ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { getApiUrl } from '@/config/app'
import { langDefault, langAvailable } from '@/config/languages'
import { fetchResource, updateResource } from '@/core/resource'
import { loadLanguageAsync, setI18nLanguage } from '@/i18n'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'
import { ResourceError } from '@/stores/resource'
import { Language, SSRContext } from '@/types'
import { logger } from '@/utils/logger'
import { push } from '@/utils/tracking'

import { poster } from '@/components/global/Poster.vue'
import { Response } from 'express'
import { debounce } from 'debounce'
import Cookies from 'js-cookie'

export const guardError = ref<ResourceError>()

gsap.registerPlugin(ScrollToPlugin)
let popstateDetected = false

if (!import.meta.env.SSR) {
  window.addEventListener('popstate', () => {
    popstateDetected = true
  })
}

export const createGuards = (
  { router, initialState, request, response, writeResponse }: SSRContext,
  pinia: Pinia
) => {
  let isFirstRendering = true

  // Manage env/feature branches
  axios.interceptors.request.use(config => {
    const isAppApiCall = config.url?.includes(getApiUrl())
    const isDev = import.meta.env.MODE === 'development'
    const isStaging = import.meta.env.MODE === 'staging'
    const isSSR = import.meta.env.SSR
    let backEnv = 'staging'

    if (!isAppApiCall && (!isDev || !isStaging)) {
      return config
    }

    const gaCookieKey = '_ga'
    const gaUserID = isSSR
      ? request?.headers?.cookie
          ?.split(';')
          .find(c => c.includes(gaCookieKey))
          ?.replace(`${gaCookieKey}=`, '')
          .trim()
      : Cookies.get(gaCookieKey)

    if (gaUserID) {
      config.headers['X-GA-CLIENT-ID'] = gaUserID
    }

    const consentCookieKey = 'epic-cookie-prefs'
    const userCookieConsent = isSSR
      ? request?.headers?.cookie
          ?.split(';')
          .find(c => c.includes(consentCookieKey))
          ?.replace(`${consentCookieKey}=`, '')
          .trim()
      : Cookies.get(consentCookieKey)

    config.headers['X-GA-CONSENT'] = userCookieConsent?.includes('advertising')
      ? 'true'
      : 'false'

    if (isSSR) {
      if (request?.headers.cookie) {
        const cookies = request.headers.cookie.split(/\s*;\s*/)
        const envBackStr = cookies.find((c: string) =>
          c.startsWith('epic_env_back')
        )

        if (envBackStr) {
          const [, envBackCookie] = envBackStr.split('=')

          backEnv = envBackCookie
        }
      }
    } else {
      backEnv = Cookies.get('epic_env_back') || backEnv
    }

    if (__FEATURE__ !== 'none') {
      backEnv = __FEATURE__
    }

    if (!config.headers) {
      config.headers = {} as AxiosHeaders
    }

    /* eslint-disable */
    config.headers.epic_env_back = backEnv
    /* eslint-enable */

    return config
  })

  // Prevent resource fetch when navigating between works categories
  router.beforeEach((to, from, next) => {
    if (to.name === 'works' && from.name === 'works') {
      return
    }

    next()
  })

  router.beforeEach((to, _from, next) => {
    if (import.meta.env.SSR) {
      return next()
    }

    if (to.hash) {
      const animate = debounce(() => {
        gsap.to(window, {
          duration: 3,
          scrollTo: {
            y: to.hash,
            autoKill: false,
          },
          onComplete: () => {
            observer.disconnect()
          },
          ease: 'power1.inOut',
        })
      }, 1000)
      const observer = new ResizeObserver(animate)

      observer.observe(document.body)
    }

    return next()
  })
  // Log + navigation
  router.beforeEach((to, from, next) => {
    logger.info('[guards] from', from.name, 'to', to.name)

    const navigation = useNavigationStore()

    // Clone and delete to avoid
    // > WARN  Cannot stringify a function component
    // const f = clone(from)
    // const t = clone(to)

    // delete f.matched
    // delete t.matched

    // navigation.from = markRaw(f)
    // navigation.to = markRaw(t)

    navigation.from = from.name
    navigation.to = to.name

    navigation.popstate = popstateDetected
    popstateDetected = false

    next()
  })

  // Manage languages
  if (langAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const chrome = useChromeStore(pinia)
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      chrome.language = lang

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      await loadLanguageAsync(langNext)
        .then(langLoaded => setI18nLanguage(langLoaded, !import.meta.env.SSR))
        .then(lang => {
          if (langHasChanged) {
            return chrome.fetchChrome(lang)
          }

          return Promise.resolve()
        })
        .catch(logger.error)

      next()
    })
  }

  // Manage env/feature branches
  // router.beforeEach((to, from, next) => {
  //   if (
  //     import.meta.env.MODE === 'staging' ||
  //     import.meta.env.MODE === 'development'
  //   ) {
  //     const featureBackCookieName = 'epic_env_back'
  //     const featureCookie = Cookies.get(featureBackCookieName) || 'staging'
  //     axios.defaults.headers.common[featureBackCookieName] = featureCookie
  //   }

  //   if (import.meta.env.SSR && import.meta.env.MODE === 'development') {
  //     if (request?.headers.cookie) {
  //       const cookies = request.headers.cookie.split(/\s*;\s*/)
  //       const envBackStr = cookies.find((c: string) =>
  //         c.startsWith('epic_env_back')
  //       )

  //       if (envBackStr) {
  //         const [, envBackCookie] = envBackStr.split('=')

  //         axios.defaults.headers.common.cookie = `epic_env_back=${envBackCookie}`
  //       }
  //     }
  //   }
  //   next()
  // })

  // Manage posters
  router.beforeEach((to, from, next) => {
    const f = from.name as string | null | undefined
    const t = to.name as string | null | undefined

    if (!import.meta.env.SSR && f) {
      const navigation = useNavigationStore(pinia)

      // Popstate : no transition from homepage to case if back button
      // Improvements : detect back to same case…

      // Hide poster
      // Poster is hidden if no "poster transition" (default fade in/out)
      if (['homepage', 'portfolio', 'case'].includes(f)) {
        if (
          f === 'case' ||
          (['homepage', 'portfolio'].includes(f) &&
            (t !== 'case' ||
              navigation.trigger === 'listing' ||
              navigation.popstate))
        ) {
          poster.hide(false)
        }
      }

      // Avoid page transition
      if (
        ['homepage', 'portfolio'].includes(f) &&
        t === 'case' &&
        navigation.trigger !== 'listing' &&
        !navigation.popstate
      ) {
        to.meta.hasTransition = false
      }
    }

    return next()
  })

  // Fetch
  // Before each route navigation we request the data needed for showing the page.
  router.beforeEach(async (to, from, next) => {
    if (Boolean(to.meta.state) && isFirstRendering) {
      // This route has state already (from server) so it can be reused.
      // State is always empty in SPA development, but present in SSR development.
      const { resource, error } = initialState

      // Display error view
      if (error && import.meta.env.MODE === 'development') {
        guardError.value = error
      }

      // Hydrate resource store
      resource && updateResource(resource)
      isFirstRendering = false

      return next()
    }

    // Explanation:
    // The first rendering happens in the server. Therefore, when this code runs,
    // the server makes a request to itself (running the code below) in order to
    // get the current page props and use that response to render the HTML.
    // The browser shows this HTML and rehydrates the application, turning it into
    // a normal SPA. After that, subsequent route navigation runs this code below
    // from the browser and get the new page props, which is this time rendered
    // directly in the browser, as opposed to the first page rendering.

    try {
      const [resource, error, headers] = await fetchResource(to)

      if (resource) {
        if (
          import.meta.env.MODE === 'production' ||
          import.meta.env.MODE === 'staging'
        ) {
          const mergedHeaders: Record<string, string> = {
            ...(response?.getHeaders() || {}),
            'Content-Type': 'text/html',
          }

          if (headers['cache-control']) {
            mergedHeaders['Cache-Control'] = headers['cache-control'] || ''
          } else {
            mergedHeaders['Cache-Control'] = 'no-store'
          }
          ;(response as Response)?.set(mergedHeaders)
        }

        if (import.meta.env.SSR && resource.template === 'notfound') {
          writeResponse({
            status: 404,
            headers: {},
          })
        }

        // First SSR rendering, render resource (@see App.vue)
        // import.meta.env.SSR && updateResource(resource)
      } else if (error && import.meta.env.MODE === 'development') {
        // Catch fetch errors.
        guardError.value = error
      }

      // This route meta state will be available as props in the page component (but also everywhere).
      // This will also be added to the initialState.
      to.meta.state = { resource, error }
    } catch (error) {
      logger.error('[guards]', error)
    }

    return next()
  })

  // // Navigation module
  // router.afterEach(() => {
  //   const navigation = useNavigationStore()

  //   navigation.trigger = undefined
  // })

  // GTM - Analytics
  router.afterEach((to, from) => {
    // Only client side, skip first display
    // if (!import.meta.env.SSR) {
    if (!import.meta.env.SSR && from.name) {
      // GTM: page view
      const layer = {
        event: 'page_view',
        pageTitle:
          to.meta.state?.resource?.head?.title ||
          to.meta.state?.resource?.content?.title ||
          document.title,
        pageLocation: window.location.href,
        pagePath: to.fullPath,
      }

      push(layer)
    }
  })
}
