<template>
  <router-link
    v-if="content?.url"
    ref="linkRef"
    :to="content.url"
    :aria-label="content.title"
    class="cta-outer"
    @click="onCtaClick(content.url)"
  >
    <div
      ref="$el"
      :class="{
        'custom-foreground': foreground,
        'custom-background': background,
      }"
      class="cta"
    >
      <div ref="$headline" class="cta__headline section-label">
        {{ content.headline }}
      </div>
      <div
        v-for="word in words"
        :key="word"
        ref="$words"
        class="title-cta cta__word"
      >
        {{ word }}
      </div>
      <div ref="$baseline" class="cta__baseline section-label">
        {{ content.baseline }}
      </div>
    </div>
    <!-- prettier-ignore -->
    <svg
      ref="$arrow"
      version="1.1"
      class="cta__arrow"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 120 120"
      style="enable-background: new 0 0 120 120;"
      xml:space="preserve"
      :class="{
        'custom-foreground': foreground,
        'custom-background': background,
      }"
    >
      <circle
        id="bgr"
        ref="$arrowCircle"
        class="cta__arrow__bgr"
        cx="60"
        cy="60"
        r="50"
      />
      <!-- eslint-disable -->
      <path
        ref="$arrowIcon"
        id="icon"
        class="cta__arrow__icon"
        d="M94.9,56.1h-0.6c-1.4-0.2-16.9-2.4-20-17.6c-0.4-2.1-2.4-3.5-4.6-3c-2.1,0.4-3.5,2.5-3,4.6
	c1.4,7.2,5.1,12.4,9.3,16.1H25.1c-2.1,0-3.9,1.7-3.9,3.9c0,2.1,1.7,3.9,3.9,3.9h50.9c-4.3,3.7-7.9,8.9-9.3,16.1
	c-0.4,2.1,0.9,4.1,3,4.6c0.3,0.1,0.5,0.1,0.8,0.1c1.8,0,3.4-1.3,3.8-3.1c3.1-15.3,18.4-17.5,20-17.6h0.6c2.1,0,3.9-1.7,3.9-3.9
	C98.8,57.9,97,56.1,94.9,56.1z"
      />
    </svg>
    <!-- eslint-enable -->
  </router-link>
</template>

<script setup lang="ts">
/* eslint-disable no-mixed-operators */
import { gsap } from 'gsap'
// import { Observer } from 'gsap/Observer'
import { CustomEase } from 'gsap/CustomEase'
import { computed, onMounted, ref, PropType } from 'vue'
import { useRoute } from 'vue-router'

import { Cta } from '@/types'
import { push } from '@/utils/tracking'

// gsap.registerPlugin(Observer)

const props = defineProps({
  content: {
    type: Object as PropType<Cta>,
    required: true,
  },
  background: {
    type: String,
    default: null,
  },
  foreground: {
    type: String,
    default: null,
  },
})

const route = useRoute()

let BCR: DOMRect
const linkRef = ref()
const $el = ref<HTMLElement | null>(null)
const $headline = ref<HTMLElement>({} as HTMLElement)
const $baseline = ref<HTMLElement>({} as HTMLElement)
const $arrow = ref<SVGElement>({} as SVGElement)
const $arrowIcon = ref<SVGPathElement>({} as SVGPathElement)
const $arrowCircle = ref<SVGCircleElement>({} as SVGCircleElement)
const $words = ref<HTMLElement[]>([])

const onCtaClick = (url?: string) => {
  if (url?.includes('contact')) {
    const ctx = linkRef.value.$el.closest('.mega-menu')
    const ctaLocation = ctx ? 'menu' : 'footer'

    push({
      event: 'contact_cta',
      ctaLocation,
      pagePath: route.fullPath,
    })
  }
}

const onMouseMove = (event: MouseEvent) => {
  if (!$el.value) {
    return
  }

  BCR = $el.value.getBoundingClientRect()
  const width = $el.value.offsetWidth
  const height = $el.value.offsetHeight
  const xPosition = event.clientX - BCR.left - width / 2
  const yPosition = event.clientY - BCR.top - height / 2
  let tx = xPosition * 0.02
  let ty = yPosition * 0.05
  $words.value.forEach(($word, index) => {
    gsap.to($word, {
      xPercent: tx,
      yPercent: ty,
      z: 100,
      rotateX: yPosition * -0.08,
      rotateY: xPosition * 0.08,
      scale: 1.05 + index * 0.05,
      onUpdate: () => {
        const tx = 50 + xPosition * 0.05
        const ty = 50 + yPosition * 0.1
        // eslint-disable-next-line max-len
        $word.style.backgroundImage = `radial-gradient(circle 120px at top ${ty}% left ${tx}%, rgba(254, 246,229, 0.1) 0, ${props.foreground} 100%)`
      },
    })
  })

  gsap.to([$headline.value, $baseline.value], {
    xPercent: tx,
    yPercent: ty,
    rotateX: yPosition * -0.1,
    rotateY: xPosition * 0.1,
  })

  tx = xPosition * 0.5
  ty = yPosition * 0.5
  gsap.to($arrow.value, {
    xPercent: tx,
    yPercent: ty,
    rotateX: yPosition * -0.1,
    rotateY: xPosition * 0.1,
    scale: 1,
  })

  tx = xPosition * 0.06
  ty = yPosition * 0.06
  gsap.to($arrowIcon.value, {
    css: {
      transform: `translateX(${tx}%) translateY(${ty}%) translateZ(500px) scale(.5)`,
    },
  })
  $arrowCircle.value.style.strokeDashoffset = '0'
}

const onMouseLeave = () => {
  const delay = 0.3
  const duration = 1
  const ease = CustomEase.create(
    'custom',
    'M0,0,C0.126,0.382,0.282,0.674,0.44,0.822,0.632,1.002,0.818,1.001,1,1'
  )

  $words.value.forEach($word => {
    gsap.to($word, {
      delay,
      duration,
      xPercent: 0,
      yPercent: 0,
      z: 0,
      rotateX: 0,
      rotateY: 0,
      scale: 1,
      ease,
      onUpdate: () => {
        $word.style.backgroundImage = `linear-gradient(0deg, ${props.foreground}, ${props.foreground})`
      },
    })
  })
  gsap.to([$headline.value, $baseline.value, $arrow.value], {
    delay,
    duration,
    xPercent: 0,
    yPercent: 0,
    rotateX: 0,
    rotateY: 0,
    ease,
  })

  gsap.to($arrow.value, {
    delay,
    scale: 0,
    ease,
  })
  $arrowCircle.value.style.strokeDashoffset = '366'
}

// const init = () => {
//   Observer.create({
//     target: $el.value,
//     onMove: observer => onMouseMove(observer.event as MouseEvent, observer),
//     onHoverEnd: onMouseLeave,
//   })
// }

const updateBCR = () => {
  BCR = $el.value!.getBoundingClientRect()
}

defineExpose({
  updateBCR,
})

onMounted(() => {
  if (!props.content?.url) {
    return
  }

  // init()
  $el.value?.addEventListener('mousemove', onMouseMove)
  $el.value?.addEventListener('mouseleave', onMouseLeave)
})

const words = computed(() => props.content.title.split('\\'))
</script>

<style lang="scss" scoped>
.cta-outer {
  position: relative;
  display: block;
  overflow: hidden;
}

.cta {
  position: relative;
  text-align: center;
  perspective: 2500px;
}

.cta__headline {
  margin-bottom: 1.5rem;
  color: var(--c-foreground);

  .custom-foreground & {
    color: v-bind('foreground');
  }
}

.cta__word {
  margin-bottom: 1.5rem;
  line-height: 1.2;
  background-clip: text;
  background-image: linear-gradient(var(--c-foreground), var(--c-foreground));
  background-size: 100%;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  user-select: none;

  .custom-foreground & {
    background-image: v-bind('foreground');
  }
}

.cta__baseline {
  color: var(--c-foreground);

  .custom-foreground & {
    color: v-bind('foreground');
  }
}

.cta__arrow {
  @include center-xy;

  z-index: 200;
  width: 12rem;
  transform: translate3d(-50%, -50%, 100px) scale(0);
  transform-origin: center;
  pointer-events: none;
}

.cta__arrow__bgr {
  stroke: var(--c-foreground);
  stroke-width: 1;
  stroke-dasharray: 366 366;
  stroke-dashoffset: 366;
  /* stylelint-disable-next-line declaration-colon-newline-after */
  transition:
    stroke-dashoffset 2s cubic-bezier(0.22, 1, 0.36, 1),
    fill 1s cubic-bezier(0.22, 1, 0.36, 1);
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: var(--c-background);
  transform-origin: center;

  .custom-foreground & {
    stroke: v-bind('foreground');
  }

  .custom-background & {
    fill: v-bind('background');
  }
}

.cta__arrow__icon {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: var(--c-foreground);
  transform-origin: center;

  .custom-foreground & {
    fill: v-bind('foreground');
  }
}
</style>
