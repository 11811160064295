import {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
} from 'axios'
import { ref, InjectionKey, DeepReadonly } from 'vue'
import { UnwrapNestedRefs } from 'node_modules/@vue/reactivity/dist/reactivity'
import { LocationQuery, RouteLocationNormalized } from 'vue-router'

import { endpointDefault, getApiUrl } from '@/config/app'
import { langDefault } from '@/config/languages'
import { segments } from '@/router/routes'
import {
  useResourceStore,
  Content,
  ResourceSingle,
  ResourceError,
} from '@/stores/resource'
import { Language } from '@/types'
import * as cache from '@/core/cache'
import { logger } from '@/utils/logger'
import Cookies from 'js-cookie'
import { Request } from 'express'

export const ContentKey: InjectionKey<DeepReadonly<UnwrapNestedRefs<Content>>> =
  Symbol('Content')
export const contentDefault: Content = {
  title: 'Default title',
  colors: {
    foreground: '#000',
    background: '#000',
  },
}
export const contentRef = ref<Content>(contentDefault)

const buildQuery = (query: LocationQuery) =>
  Object.keys(query).reduce((prev, cur) => `${prev}${cur}=${query[cur]}&`, '?')

// Manage HTML lang attribute…
const setHtmlLang = (res: ResourceSingle, lang: Language) => {
  const resource = typeof res === 'string' ? ({} as ResourceSingle) : res

  if (!resource.head) {
    resource.head = {}
  }

  if (resource.head.htmlAttrs) {
    resource.head.htmlAttrs.lang = lang
  } else {
    resource.head.htmlAttrs = { lang }
  }

  return resource
}

/**
 * Parse route to build an API request like ${api}/${endpoint}/${resource}.
 */
export const parseRoute = (to: RouteLocationNormalized) => {
  const { path, meta, name, params, query } = to
  const api = meta.api || getApiUrl()
  let endpoint = endpointDefault
  let resource = path

  // REVIEW: purpose???
  // const allowedParams = {
  //   news: ['categories', 'categories[]'],
  // }

  if (params.lang) {
    // Remove lang from path
    resource = resource.replace(new RegExp(`^/${params.lang}`), '')
  }

  if (meta.endpoint) {
    // Set API endpoint
    ;({ endpoint } = meta)
    // Remove endpoint from resource path
    // Check for a named route params or a registered segment
    /* eslint-disable indent */
    const pattern =
      params.endpoint || segments[endpoint]
        ? `(${endpoint}|${
            Array.isArray(segments[endpoint])
              ? (segments[endpoint] as string[]).join('|')
              : (segments[endpoint] as string)
          })`
        : endpoint
    /* eslint-enable indent */
    resource = resource.replace(new RegExp(`^/${pattern}`), '')
  }

  if (query.preview === 'true') {
    // Manage preview
    const {
      page_id: pageId,
      preview_id: previewId,
      id: postId,
      p: postType,
    } = query

    if (pageId || previewId) {
      resource = `/${pageId || previewId}`
    }

    if (postType && postId) {
      endpoint = postType as string
      resource = `/${postId}`
    }
  }

  // if (query['categories[]']) {
  //   const categories: string[] = Array.isArray(query['categories[]'])
  //     ? (query['categories[]'] as string[])
  //     : [query['categories[]']]
  //   resource += `?categories[]=${categories.join('&categories[]=')}`
  // }

  if (name === '404') {
    resource = 'supercalifragilisticexpialidocious'
  }

  resource = resource.replace(/^\//, '')

  return {
    api,
    endpoint,
    resource,
  }
}

export async function fetchResource(
  to: RouteLocationNormalized,
  request?: Request
): Promise<
  [ResourceSingle | null, ResourceError | null, AxiosResponseHeaders]
> {
  const config: AxiosRequestConfig = {}
  const { fullPath, meta, params, query } = to
  const lang = (params.lang as Language) || langDefault

  // No fetch for static routes
  if (meta.static) {
    return [
      {
        content: {},
        languages: {},
      } as ResourceSingle,
      null,
      {},
    ]
  }

  const { api, endpoint, resource } = parseRoute(to)

  if (query && query.preview === 'true') {
    // Preview header
    config.headers = {
      'x-preview': 'true',
    }
  }

  config.headers = {
    ...(config.headers || {}),
    'x-light-mode': Cookies.get('light_mode') || '',
  }

  if (request?.headers['X-Ecache-Disable']) {
    !config.headers && (config.headers = {})
    config.headers['X-Ecache-Disable'] = request.headers[
      'X-Ecache-Disable'
    ] as string
  }

  const url = `${api}/${endpoint}/${resource}${buildQuery(query)}`
  // const url = `${api}/${endpoint}/${routeParam}`

  logger.info('[fetchResource]', url)

  try {
    const response = (await cache.fetch(
      fullPath,
      url,
      config
    )) as AxiosResponse<ResourceSingle>

    // Nested, so what?
    // if (params.nested || meta.nested) {}
    const resource = setHtmlLang(response.data as ResourceSingle, lang)

    return [resource, null, response.headers]
  } catch (error) {
    const { isAxiosError } = error as AxiosError

    if (isAxiosError) {
      const { response } = error as AxiosError

      if (response && response.status === 404) {
        const resource = setHtmlLang(response.data as ResourceSingle, lang)

        return [resource, error as AxiosError, response.headers]
      }
    }

    logger.error(
      '[fetchResource]',
      (error as AxiosError).code || (error as Error).message
    )

    return [null, isAxiosError ? (error as AxiosError) : (error as Error), {}]
  }
}

/**
 * Fetch resource from API directly
 * without being related on routing.
 */
export async function fetchAPI(to: RouteLocationNormalized) {
  try {
    const { fullPath, query } = to
    const { api, endpoint, resource } = parseRoute(to)
    const url = `${api}/${endpoint}/${resource}${buildQuery(query)}`

    logger.info('[fetchAPI]', url)

    const response = (await cache.fetch(
      fullPath,
      url
    )) as AxiosResponse<ResourceSingle>

    return response.data as ResourceSingle
  } catch (error) {
    logger.error('[fetchAPI]', error)

    return null
  }
}

// TODO: types
// export const updateResource = <T extends ResourceSingle | ResourceArchive>({
export const updateResource = (resource: ResourceSingle) => {
  const { content, id, head, languages, slug, template, type, url } = resource

  contentRef.value = content as Content

  // Use a function rather than object (`$patch(object)`)
  // to make a "clean" reset of props (persistent previous properties)
  useResourceStore().$patch(state => {
    state.content = content
    state.id = id
    state.head = head
    state.languages = languages
    state.slug = slug
    state.template = template
    state.type = type
    state.url = url
  })
}
